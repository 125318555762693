import React, { lazy } from 'react';
import { Routes, Route } from "react-router-dom";

const ModelAndroid = lazy(() => import("./screens/Model/ModelAndroid"));
const ModelIOS = lazy(() => import("./screens/Model/ModelIOS"));

function App() {
  return (
    <Routes>
      <Route path={`/model-ios`} element={<ModelIOS />} />
      <Route path={`/model-android`} element={<ModelAndroid />} />
    </Routes>
  );
}

export default App;
